import React, { useEffect, useState, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid';
import _ from "lodash"
import swal from 'sweetalert';
import { makeStyles } from '@material-ui/core/styles';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { Dialog, Grid, Slide, alpha, CircularProgress } from '@material-ui/core'
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import SideMenuSigners from './SideMenuSigners';
import DrawerHelper from "./DrawerHelper"
import NavBar from './NavBar';
import { getSetSigners, saveOrModifyStickers } from '../../../../core/http/functionRequests';
import { getPdfSet } from "../../../../core/http/functionRequests";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../../core/actions";
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;


const mainScale = 1.5;
const minLimits = { square: { w: 25, h: 25 }, titleBackgroundH: 14 };


const useStyles = makeStyles((theme) => ({
  sideBar: {
    width: '360px',
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
  },
  container: {
    height: "100%"
  },
  gridContainer: {
    height: '90vh',
    overflow: 'auto'
  },
  canvasContainer: {
    overflow: 'auto',
    padding: "10px"
  },
  canvas: {
    position: 'static',
    cursor: 'pointer',
    boxShadow: '0 0 10px rgb(121, 131, 125)'
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StickersPosition = (props) => {
  const {
    open,
    onClose,
    documents,
    vaultId,
    path,
  } = props;

  const classes = useStyles();

  const canvasRef = useRef(null);
  const dispatch = useDispatch();
  const contanierRef = useRef(null);

  const currentuser = useSelector(state => state.authReducer.currentUser);
  const vault = useSelector(state => state.fileSystemReducer.vault);

  const [currentDocument, setCurrentDocument] = useState(null);
  const [load, setLoad] = useState(false);
  const [pdfRef, setPdfRef] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [zoom, setZoom] = useState(1);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const [offsetZoomX, setOffsetZoomX] = useState(0)
  const [offsetZoomY, setOffsetZoomY] = useState(0)
  const [totalPages, setTotalPages] = useState(0);
  const [signers, setSigners] = useState([]);
  const [selectedText, setSelectedText] = useState(null);
  const [frame, setFrame] = useState({ w: 160, h: 50 });
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [scale, setScale] = useState(null);
  const [changeSize, setChangeSize] = useState(false);
  const [pageRendering, setPageRendering] = useState(false);
  const [pageNumPending, setPageNumPending] = useState(null);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [inputValue, setInputValue] = useState(1);
  const [firstOpen, setFirstOpen] = useState(true);
  const [signersDocument, setSignersDocument] = useState({})

  const filterItem = (usr) => signersDocument[currentDocument.id].filter((e) => e.userId === usr.id).length !== 0;
  const validateStickersGenerated = (userId, stickers) => (stickers.filter((item) => item.userId === userId).length > 0);


  const renderPage = async (pdf, pageNum, stickers, showDelete, deleteX, deleteY) => {
    if (pdf) {
      setPageRendering(true)
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: mainScale });
      const canvas = canvasRef.current;
      if (!canvas) return
      const context = canvas.getContext('2d');
      const actualScale = ((window.innerHeight - 64) / viewport.height) * 0.95;

      canvas.width = Math.floor(viewport.width);
      canvas.height = Math.floor(viewport.height);
      canvas.style.width = Math.floor(viewport.width) * actualScale * zoom + "px";
      canvas.style.height = Math.floor(viewport.height) * actualScale * zoom + "px";

      const renderContext = {
        canvasContext: context,
        viewport: viewport
      };
      const renderPromise = page.render(renderContext);

      const canvasOffset = canvas?.getBoundingClientRect();
      setTotalPages(pdf.numPages);
      setOffsetX(Math.floor(canvasOffset.x));
      setOffsetY(74);// It should be canvasOffset.top, but it is making some trouble.
      setCanvasWidth(canvas.width);
      setCanvasHeight(canvas.height);
      setScale(actualScale);

      renderPromise.promise.then(() => {
        setPageRendering(false)
        if (pageNumPending !== null) {
          setPageNumPending(null)
        }
        stickers.reduce((acc, text) => {
          if (text.page === currentPage) {
            text.scale = actualScale
            text.zoom = zoom;
            DrawerHelper.drawSquare(context, text)
          }
          return acc
        }, [])

        if (showDelete) {
          const obj = { x: deleteX, y: deleteY, scale: actualScale * zoom, zoom, }
          DrawerHelper.drawDeleteMessage(context, obj)
        } else {
          setShowDeleteMessage(false);
        }
      })
    }
  }

  const updateRender = (stickers = [], showDeleteMessage, x, y) => {
    const canvas = canvasRef.current;
    const ctxs = canvas.getContext('2d');
    ctxs.clearRect(0, 0, canvas.width, canvas.height);
    if (pageRendering) {
      setPageNumPending(currentPage)
    } else {
      renderPage(pdfRef, currentPage, stickers, showDeleteMessage, x, y)
    }
  }

  const handleMouseDown = (e) => {
    const x = e.clientX;
    const y = e.clientY;
    const tempStartX = Math.floor(x - offsetX + offsetZoomX);
    const tempStartY = Math.floor(y - offsetY + offsetZoomY);


    const signatures = signersDocument[currentDocument.id]
    if (!signatures) return
    signatures.forEach((text) => {
      text.zoom = zoom;
      if (text.page === currentPage) {
        if (DrawerHelper.checkInDeleteSticker(tempStartX, tempStartY, text)) {
          const updateStickers = signatures.filter(el => el.stickerId !== text.stickerId)
          setSignersDocument(prev => ({ ...prev, [currentDocument.id]: updateStickers }))
          updateRender(updateStickers, false, 0, 0)
        } else if (DrawerHelper.textHit(tempStartX, tempStartY, text)) {
          setSelectedText(text);
        }
        if (DrawerHelper.checkInRightBorder(tempStartX, tempStartY, text)) {
          setChangeSize(true);
        }
      }
    });

  }

  const handleMouseMove = (e) => {
    const documentSignatures = signersDocument[currentDocument.id]
    if (!documentSignatures || documentSignatures.length === 0) return

    e.preventDefault();
    const x = e.clientX;
    const y = e.clientY;
    const canvasD = canvasRef.current;
    const tempStartX = Math.floor(x - offsetX + offsetZoomX);
    const tempStartY = Math.floor(y - offsetY + offsetZoomY);

    const inStickerReducer = (acc, text) => {
      if (text.page === currentPage) {
        return acc || DrawerHelper.textHit(tempStartX, tempStartY, text)
      }
      return acc
    }

    const inDeleteStickerReducer = (acc, text) => {
      if (text.page === currentPage) {
        return acc || DrawerHelper.checkInDeleteSticker(tempStartX, tempStartY, text)
      }
      return acc
    }

    const inRightBorderReducer = (acc, text) => {
      if (text.page === currentPage) {
        return acc || DrawerHelper.checkInRightBorder(tempStartX, tempStartY, text)
      }
      return acc
    }

    const isCursorInSticker = documentSignatures.reduce(inStickerReducer, false);
    const isCursorInDeleteSticker = documentSignatures.reduce(inDeleteStickerReducer, false);
    const isCursorInRightBorder = documentSignatures.reduce(inRightBorderReducer, false);

    if (!isCursorInDeleteSticker && showDeleteMessage) {
      updateRender(documentSignatures, false, 0, 0)
    }

    if (isCursorInRightBorder) {
      canvasD.style.cursor = 'ne-resize';
    } else if (isCursorInDeleteSticker && !selectedText) {
      canvasD.style.cursor = 'pointer';
      setShowDeleteMessage(true);
      updateRender(documentSignatures, true, tempStartX, tempStartY)
    } else if (isCursorInSticker) {
      canvasD.style.cursor = 'grab';
    } else {
      canvasD.style.cursor = 'pointer'
    };

    if (!selectedText) return;
    if (x < offsetX) return;

    const dx = e.movementX
    const dy = e.movementY

    const updateStickers = documentSignatures.map(text => {
      const { scale, zoom } = text
      if (changeSize && text.stickerId === selectedText.stickerId) {
        const newWidth = text.squareW + (dx / (scale * zoom));
        const newHeight = text.squareH - (dy / (scale * zoom));

        if (newWidth > (minLimits.square.w / (scale * zoom))) {
          text.squareW = newWidth;
        }
        if (newHeight > (minLimits.square.h / (scale * zoom))) {
          text.squareH = newHeight;
          text.y += (dy / (scale * zoom));
        }

        setFrame({ w: text.squareW * scale, h: text.squareH * scale })


      } else if (text.stickerId === selectedText.stickerId && !changeSize) {

        const newPositionX = text.x + (dx / (scale * zoom));
        const newPositionY = text.y + (dy / (scale * zoom));

        if (newPositionX >= 0 && newPositionX + text.squareW <= text.maxEjeX) {
          text.x = newPositionX;
        }
        if (newPositionY >= 0 && newPositionY + text.squareH <= text.maxEjeY) {
          text.y = newPositionY;
        }
      }

      return text
    });
    setSignersDocument(prev => ({ ...prev, [currentDocument.id]: updateStickers }))
    updateRender(updateStickers, false, 0, 0)
  }

  const handleMouseUp = (e) => {
    setSelectedText(null);
    setChangeSize(false);
  }

  const handleMouseOut = (e) => {
    setSelectedText(null);
    setChangeSize(false);
  }

  const handleScroll = (e) => {
    const top = Math.floor(e.target.scrollTop)
    const left = Math.floor(e.target.scrollLeft)
    setOffsetZoomY(top)
    setOffsetZoomX(left)

  }

  const handleGenerateTag = (name, userId) => {
    const currentStickers = signersDocument[currentDocument.id] ?? [];

    const qtyStickers = (currentStickers.filter(text => text.page === currentPage)).length;

    const y = (qtyStickers % 13 + 1) * 30 + 55;
    const stickerName = '[' + name.split('').slice(0, 22).join('') + '...' + ']';

    const text = {
      stickerId: uuidv4(),
      documentId: currentDocument.id,
      vaultId,
      path,
      name: name,
      page: currentPage,
      userId: userId,
      text: name.length > 23 ? stickerName : name,
      x: Math.floor(40 / scale),
      y: Math.floor(y / scale),
      maxEjeX: canvasWidth,
      maxEjeY: canvasHeight,
      coordConvertX: 0,
      coordConvertY: 0,
      scale: scale,
      zoom: zoom,
      width: 0,
      height: 24,
      squareH: Math.floor(frame.h / scale),
      squareW: Math.floor(frame.w / scale),
      titleH: Math.floor(minLimits.titleBackgroundH / scale),
    }

    const documentsStickers = signersDocument[currentDocument.id]
      ? [...signersDocument[currentDocument.id], text]
      : [text]
    setSignersDocument(prev => ({
      ...prev,
      [currentDocument.id]: documentsStickers
    }))
    updateRender(documentsStickers, false, 0, 0)
  }

  const handleSaveStickers = async (finish) => {
    const sendSignatures = _.cloneDeep(signersDocument);

    const allStickers = Object.values(sendSignatures).flat()

    const signatures = allStickers.reduce((acc, text) => {
      const { x, y, maxEjeY, squareH, squareW, scale } = text;
      const coordX = Math.ceil(x * scale) - 2;
      const coordY = Math.ceil((maxEjeY - y - squareH) * scale);

      text.squareHOriginal = squareH
      text.squareWOriginal = squareW
      text.squareH = Math.ceil(squareH * scale)
      text.squareW = Math.ceil(squareW * scale)
      text.coordConvertX = coordX
      text.coordConvertY = coordY
      text.scale = text.scale * mainScale
      return [...acc, text];
    }, []);
    console.log('drawSignature', signatures);

    const body = {
      stickers: signatures,
      vaultId,
      path,
    }
    try {
      setLoad(true);
      await saveOrModifyStickers(body)
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        dispatch(authActions.userLoggedOut())
      }
    } finally {
      setLoad(false);
      if (finish) {
        onClose(true)
      } else {
        updateRender(signersDocument[currentDocument.id], false, 0, 0)
      }
    }
  }

  const onNextDocument = (doc) => {
    setCurrentPage(1)
    setCurrentDocument(doc)
    if (!signersDocument[doc.id]) {
      setSignersDocument(prev => ({ ...prev, [doc.id]: [] }))
    }
  }

  const pageByInputRequest = () => {
    if (inputValue < 1 || inputValue > totalPages) {
      swal('Aviso', 'El numéro no es una opción válida de página', 'warning');
    } else {
      const pages = inputValue;
      setCurrentPage(pages);
    }
  };

  const onChangeHandler = (event) => {
    setInputValue(parseInt(event.target.value));
  };

  const handleNextPage = () => {
    if (currentPage >= totalPages) {
      return;
    }
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage <= 1) {
      return;
    }
    setCurrentPage(currentPage - 1);
  };

  const handleClose = () => {
    setFirstOpen(true)
    onClose()
  }

  useEffect(() => {
    if (!open) return

    setSigners(oldSigners => oldSigners.map(el => (
      {
        ...el,
        check: validateStickersGenerated(el.id, signersDocument[currentDocument.id] ?? [])
      }
    )))
    // eslint-disable-next-line
  }, [signersDocument, currentDocument?.id])

  useEffect(() => {
    if (!open) return
    if (!firstOpen) {
      updateRender(signersDocument[currentDocument.id], false, 0, 0)
    }
    // eslint-disable-next-line
  }, [zoom, currentPage])

  useEffect(() => {

    if (!open) return
    if (!currentDocument) return
    if (pageRendering) {
      setPageNumPending(currentPage)
      return
    }
    let isMounted = true;
    const controller = new AbortController();
    const signal = controller.signal;

    const getPdf = async (signers = []) => {
      setLoad(true);

      const body = {
        documentId: currentDocument.id,
        companyId: vault.id,
        owner: currentuser.userId,
        path: currentDocument.path,
        placingStickers: true,
      }

      try {
        const doc = await getPdfSet(body, signal)
        const url = URL.createObjectURL(doc);
        const loadingTask = pdfjsLib.getDocument(url);
        const pdf = await loadingTask.promise;
        if (!isMounted) return;
        setPdfRef(pdf)
        setFirstOpen(false)
        renderPage(pdf, 1, signers, false, 0, 0);
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          dispatch(authActions.userLoggedOut())
        }
      }
    }
    const requestSigners = async () => {
      try {
        const signersReq = await getSetSigners(vaultId, { path, include: "stickers" }, signal);
        setSigners(signersReq.signers);

        const documentsSticker = signersReq.stickers;

        const processStickers = Object.keys(documentsSticker).reduce((prev, docId) => {
          const process = documentsSticker[docId].reduce((acc, curr) => {
            const text = { ...curr }
            text.scale = text.scale / mainScale
            text.zoom = zoom;
            if (text.squareHOriginal) {
              text.squareH = text.squareHOriginal
              text.squareHOriginal = null
            }
            if (text.squareWOriginal) {
              text.squareW = text.squareWOriginal
              text.squareWOriginal = null
            }
            return [...acc, text]
          }, [])

          return { ...prev, [docId]: process }
        }, {})

        if (Object.values.length > 0) {
          setSignersDocument(processStickers)
          await getPdf(processStickers[currentDocument.id])
        }
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          dispatch(authActions.userLoggedOut())
        }
      }
    }
    if (firstOpen) {
      setLoad(true)
      requestSigners()
    } else {
      getPdf(signersDocument[currentDocument.id])
    }

    return () => {
      controller.abort(); // Cancelar la petición si el componente se desmonta
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [currentDocument?.id, open])

  useEffect(() => {
    if (documents.length > 0) {
      setCurrentDocument(documents[0])
    }
  }, [documents])

  useEffect(() => {
    if (pdfRef) {
      setLoad(false)
    }
  }, [pdfRef])

  return (
    <Dialog open={open} fullScreen maxWidth={false} TransitionComponent={Transition} >
      <NavBar
        onClose={handleClose}
        documents={documents}
        onNextDocument={onNextDocument}
        handlePrevPage={handlePrevPage}
        currentPage={currentPage}
        totalPages={totalPages}
        handleNextPage={handleNextPage}
        onChangeHandler={onChangeHandler}
        inputValue={inputValue}
        pageByInputRequest={pageByInputRequest}
        zoom={zoom}
        setZoom={setZoom}
      />
      <Grid container className={classes.container}>

        <Grid
          item
          container
          className={classes.sideBar}
        >
          <SideMenuSigners
            loading={load}
            signers={signers}
            allDocumentsReady={
              documents?.every(doc => signersDocument[doc.id]?.length > 0) &&
              signers.every(signer =>
                Object.values(signersDocument).flat()
                  .some(sticker => sticker.userId === signer.id))
            }
            saveStickers={handleSaveStickers}
            handleGenerateTag={handleGenerateTag}
          />
        </Grid>

        <Grid
          item
          container
          xs
          justifyContent='center'
          alignItems='center'
          className={classes.gridContainer}
          ref={contanierRef}
          onScrollCapture={handleScroll}
        >
          {
            (currentDocument && open) ? (
              <div
                className={classes.canvasContainer}
              >
                {load && <CircularProgress />}
                {
                  !load && (
                    <canvas
                      id="canvas"
                      className={classes.canvas}
                      ref={canvasRef}
                      onMouseDown={handleMouseDown}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseUp}
                      onMouseOut={handleMouseOut}
                    />
                  )
                }
              </div>
            ) : <></>
          }
        </Grid>

      </Grid>
    </Dialog>
  )
}

export default StickersPosition