import { useEffect, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from '@material-ui/icons/Delete';
import FormCompleteUserInfo from "../../team/users/FormCompleteUserInfo";

const useStyles = makeStyles((theme) => ({
  name: {
    display: "block",
    whiteSpace: "nowrap",
    textOverflow: 'ellipsis',
    overflow: "hidden",
    width: "100%",
    paddingRight: '5px',
  },
  email: {
    display: "block",
    whiteSpace: "nowrap",
    textOverflow: 'ellipsis',
    overflow: "hidden",
    width: "100%",
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  selector: {
    fontSize: "13px",
    width: '100px'
  }
}));

const objectSignature = {
  link: "Liga por correo",
  email: "Correo con Contraseña",
  rfc: "R.F.C.",
  token: "Token",
};

const objectStroke = {
  any: "Cualquiera",
  desc: "Descriptiva",
  stroke: "Dibujo",
  name: "Nombre",
  hash: "Digestión",
  qr: "QR",
};

const SignerSelected = ({
  signer,
  onDelete,
  onChange,
  availableUsers = [],
  selectedSigners = [],
  toComplete = false,
  availableSignatures,
}) => {

  const classes = useStyles();

  const [methodOptions, setMethodOptions] = useState([]);
  const [strokeOptions, setStrokeOptions] = useState([]);
  const [selectedStroke, setSelectedStroke] = useState('');
  const [selectedMethod, setSelectedMethod] = useState('');
  const [openComplete, setOpenComplete] = useState(false);


  const handleChangeStroke = (e) => {
    const value = e.target.value;
    onChange(signer.id, 'stroke', value)
    setSelectedStroke(value);
  };

  const handleChangeMethod = (e) => {
    const value = e.target.value;
    onChange(signer.id, 'method', value)
    setSelectedMethod(value);
  };

  const availableOptions = () => {

    const methodsMap = availableSignatures.map((el) => ({
      title: objectSignature[el],
      value: el,
    }));

    const strokeMap = Object.keys(objectStroke).map((el) => ({
      title: objectStroke[el],
      value: el,
    }));

    const optionsSet = signer.rfc
      ? methodsMap
      : methodsMap.filter((op) => op.value !== "rfc");

    setMethodOptions(optionsSet);
    setStrokeOptions(strokeMap);
    setSelectedStroke(signer.stroke)
    setSelectedMethod(signer.method)
  };

  const handleCompleteUser = (data) => {
    if (data.rfc) {
      onChange(signer.id, 'rfc', data.rfc)
    }
    if (data.name !== signer.name) {
      onChange(signer.id, 'name', data.name)
    }
    setOpenComplete(false)
  }

  useEffect(() => {
    availableOptions();
    // eslint-disable-next-line
  }, [signer.rfc]);

  return (
    <>
      <Grid item container xs={12} spacing={1} alignItems="center">

        <Grid
          item
          xs={4}
          className={classes.email}
        >
          <label title={signer.email}>
            {signer.email}
          </label>
        </Grid>

        <Grid
          item
          xs={toComplete ? 3 : 4}
          className={classes.name}
        >
          <label title={signer.name}>
            {signer.name}
          </label>
        </Grid>

        {
          toComplete ? (
            <Grid item xs={1}>
              <Button
                title="Completar"
                color="primary"
                variant="contained"
                onClick={() => setOpenComplete(true)}
                size="small"
                style={{ textSizeSmall: "10px" }}
              >
                comp
              </Button>
            </Grid>
          ) : <></>
        }

        <Grid item container xs={4} justifyContent="flex-end" spacing={1}>

          <Grid item>
            <FormControl>
              <Select
                className={classes.selector}
                value={selectedMethod}
                onChange={(e) => handleChangeMethod(e)}
                title={objectSignature[selectedMethod]}
                style={{ border: 0 }}
              >
                {methodOptions.map((el) => (
                  <MenuItem key={el.value} value={el.value}>
                    {el.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item>
            <FormControl>
              <Select
                className={classes.selector}
                value={selectedStroke}
                onChange={handleChangeStroke}
                title={objectStroke[selectedStroke]}
              >
                {strokeOptions.map((el) => (
                  <MenuItem key={el.value} value={el.value}>
                    {el.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item>
            <IconButton
              title={`Borrar ${signer.email}`}
              onClick={() => onDelete(signer.id)}
              style={{ padding: 0 }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>

        </Grid>



      </Grid>

      {
        openComplete ? (
          <FormCompleteUserInfo
            open={openComplete}
            signer={signer}
            onCancel={() => setOpenComplete(false)}
            selectedSigners={selectedSigners.filter(el => el.id !== signer.id)}
            availableUsers={availableUsers}
            submitActions={handleCompleteUser}
          />
        ) : <></>
      }

    </>
  )
}

export default SignerSelected