import { useEffect, useState } from 'react';
import {
  Grid,
  List,
  Button,
  Dialog,
  Checkbox,
  ListItem,
  TextField,
  DialogTitle,
  ListItemText,
  DialogActions,
  DialogContent,
  InputAdornment,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import FormTemporalUser from './FormTemporalUser';
import { AlertConfirm } from '../../../from-library/alerts/AlertConfirm';

export const UserSelector = ({
  open, users, selected, currentSigners, setUsers, onSelect, onClose, allowExternal = false
}) => {

  const [checked, setChecked] = useState(selected);
  const [openExternalForm, setOpenExternalForm] = useState(false);
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const [temporalSelected, setTemporalSelected] = useState(null);
  const [searchResult, setSearchResult] = useState(users)
  const [search, setSearch] = useState("")

  const handleChange = (user) => {
    const currentIndex = checked.map(e => e.id).indexOf(user.id);
    const newChecked = [...checked];

    if (!!user.temporal) {
      setTemporalSelected(user.id);
      setOpenConfirmAlert(true);
      return
    }

    if (currentIndex === -1) {
      newChecked.push(user);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  }

  const handleAddUsers = () => {
    onSelect(checked)
    onClose()
  }

  const handleSubmitExternalSigners = (temporal) => {
    setUsers(prev => [...prev, temporal])
    const newChecked = [...checked, temporal];
    setChecked(newChecked);
    setOpenExternalForm(false)
  }

  const handleChangeSearch = (e) => {
    const value = e.target.value
    setSearch(value)

    if (value.trim() === '') {
      setSearchResult(users)
    }

    const regex = new RegExp(value, "gi");
    setSearchResult(users.filter(usr => regex.test(usr.email) || regex.test(usr.name)))
  }

  useEffect(() => {
    setSearchResult(users)
  }, [users])

  return (
    <>
      <Dialog open={open}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
          <DialogTitle>Seleccionar Usuarios</DialogTitle>
          {
            allowExternal && (
              <div style={{ padding: '20px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => { setOpenExternalForm(true) }}
                >
                  Añadir Externo
                </Button>
              </div>
            )
          }
        </div>
        <DialogContent style={{ paddingTop: "0px", marginTop: "0px" }}>
          <Grid container spacing={1}>

            <Grid item container>
              <TextField
                value={search}
                onChange={handleChangeSearch}
                variant='outlined'
                size='small'
                margin='none'
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item container spacing={1}>
              <List style={{ paddingTop: "0px" }}>
                {
                  searchResult.map((item, index) => (
                    <ListItem
                      key={`${item.id}-${index}`}
                      onClick={() => handleChange(item)}
                      style={{ paddingTop: '0px', paddingBottom: "0px" }}
                    >
                      <Checkbox
                        id={`check${item.id}`}
                        checked={checked.some(some => some.id === item.id)}
                      />
                      <ListItemText primary={item.name ? item.name : item.email} />
                    </ListItem>
                  ))
                }
              </List>
            </Grid>
          </Grid>
          {
            users.length === 0 && (
              <div className="text-center mt-2">
                No hay usuarios con permiso para firmar
              </div>
            )
          }
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-end" }} >
          <Button
            className="primary-text"
            color="primary"
            variant="outlined"
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddUsers}
          >
            Aceptar
          </Button>

        </DialogActions>
      </Dialog>

      {
        openExternalForm && (
          <FormTemporalUser
            open={openExternalForm}
            usersSelected={selected}
            signersSelected={currentSigners}
            availableUsers={users}
            submitActions={handleSubmitExternalSigners}
            onCancel={() => setOpenExternalForm(false)}
          />
        )
      }

      {
        openConfirmAlert && (
          <AlertConfirm
            open={openConfirmAlert}
            onCancel={() => setOpenConfirmAlert(false)}
            onConfirm={() => {
              setUsers(prev => prev.filter(el => el.id !== temporalSelected));
              setChecked(curr => curr.filter(el => el.id !== temporalSelected))
              setTemporalSelected(null);
              setOpenConfirmAlert(false);
            }}
            textContent="El usuario se eliminará de la lista. ¿Deseas continuar?"
          />
        )
      }

    </>
  )
}