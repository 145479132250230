import { fileSystemConstants } from "../constants/fileSystem.constants";


const initialState = {
  sets: [],
  folders: [],
  vault: null,
  documents: [],
  arrayPath: [],
  filterTags: [],
  loading: false,
  searchValue: "",
  foldersSearch: [],
  itemSelected: null,
  documentsSearch: [],
  openBackDrop: false,
  currentFolderId: null,
  includeDescription: false,
}

export const fileSystemReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === fileSystemConstants.SAVE_ITEMS_FILE_SYSTEM) {
    return {
      ...state,
      loading: false,
      filterTags: [],
      vault: payload.vault,
      folders: payload.subfolders,
      sets: payload.signatureSets,
      documents: payload.documents,
      arrayPath: [payload.vault?.name],
      foldersSearch: payload.subfolders,
      documentsSearch: payload.documents,
      currentFolderId: payload.currentFolderId,
    }
  }
  if (type === fileSystemConstants.LOADING_ITEMS) {
    return {
      ...state,
      loading: payload
    }
  }
  if (type === fileSystemConstants.SELECT_ITEM) {
    return {
      ...state,
      itemSelected: payload
    }
  }

  if (type === fileSystemConstants.SAVE_ARRAY_PATH) {
    return {
      ...state,
      arrayPath: payload
    }
  }

  if (type === fileSystemConstants.OPEN_BACKDROP) {
    return {
      ...state,
      openBackDrop: payload
    }
  }

  if (type === fileSystemConstants.SEARCH_ELEMENTS) {
    const regex = payload.trim() === '' ? null : new RegExp(payload.trim(), 'i')
    // para que permita el parentesis
    // const firstReplace = payload.replace("(", "\\(")
    // const result = firstReplace.replace(")", "\\)")
    // const regex = new RegExp(`${result.trim()}`, 'gi')
    const processDocuments = state.documents?.filter(doc => {
      const { name, description } = doc;
      if (!regex) {
        return doc;
      } else {
        if (state.includeDescription) {
          return (name.match(regex) || description.match(regex))
        }
        return name.match(regex)
      }
    })

    const processFolders = state.folders?.filter(folder => {
      const { name } = folder;
      if (!regex) {
        return folder
      } else {
        return name.match(regex)
      }
    })

    const newDocumentsSearch = state.filterTags.length > 0
      ? processDocuments.filter(doc => state.filterTags.every(tag => doc.tags.includes(tag)))
      : processDocuments
    const newFoldersSearch = state.filterTags.length > 0
      ? []
      : processFolders

    return {
      ...state,
      searchValue: payload.trim(),
      foldersSearch: newFoldersSearch,
      documentsSearch: newDocumentsSearch,
    }
  }

  if (type === fileSystemConstants.SET_INCLUDE_DESCRIPTION) {
    const { description, value } = payload
    const regex = new RegExp(value.trim(), 'gi')

    const processDocuments = state.documents?.filter(doc => {
      const { name } = doc;
      if (description) {
        return (name.match(regex) || doc.description.match(regex))
      }
      return name.match(regex)
    })

    const processFolders = state.folders?.filter(folder => {
      const { name } = folder;
      return name.match(regex)
    })

    const newDocumentsSearch = state.filterTags.length > 0
      ? processDocuments.filter(doc => state.filterTags.every(tag => doc.tags.includes(tag)))
      : processDocuments
    const newFoldersSearch = state.filterTags.length > 0
      ? []
      : processFolders

    return {
      ...state,
      searchValue: value.trim(),
      includeDescription: description,
      foldersSearch: newFoldersSearch,
      documentsSearch: newDocumentsSearch,
    }
  }

  if (type === fileSystemConstants.ADD_FILTER_TAG) {
    const newFilterTags = [...state.filterTags, payload]

    const regex = new RegExp(state.searchValue, 'gi')

    const processDocuments = state.documents?.filter(doc => {
      const { name } = doc;
      if (state.includeDescription) {
        return (name.match(regex) || doc.description.match(regex))
      }
      return name.match(regex)
    })

    const processFolders = state.folders?.filter(folder => {
      const { name } = folder;
      return name.match(regex)
    })

    const newDocumentsSearch = processDocuments.filter(doc => newFilterTags.every(tag => doc.tags.includes(tag)))

    const newFoldersSearch = newFilterTags.length > 0 ? [] : processFolders

    return {
      ...state,
      filterTags: newFilterTags,
      documentsSearch: newDocumentsSearch,
      foldersSearch: newFoldersSearch,
    }
  }

  if (type === fileSystemConstants.REMOVE_FILTER_TAG) {
    const newFilterTags = state.filterTags.filter(tag => tag !== payload)

    const regex = new RegExp(state.searchValue, 'gi')

    const processDocuments = state.documents?.filter(doc => {
      const { name } = doc;
      if (state.includeDescription) {
        return (name.match(regex) || doc.description.match(regex))
      }
      return name.match(regex)
    })

    const processFolders = state.folders?.filter(folder => {
      const { name } = folder;
      return name.match(regex)
    })

    const newDocumentsSearch = newFilterTags.length > 0
      ? state.documents.filter(doc => newFilterTags.every(tag => doc.tags.includes(tag)))
      : processDocuments

    const newFoldersSearch = newFilterTags.length > 0
      ? []
      : processFolders

    return {
      ...state,
      filterTags: newFilterTags,
      documentsSearch: newDocumentsSearch,
      foldersSearch: newFoldersSearch,
    }
  }

  return state

}
