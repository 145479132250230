import { useState } from "react";
import { TextFieldNative } from "../../control/calendar/forms/fields/text-field-native";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";

const FormCompleteUserInfo = ({ submitActions, onCancel, open, selectedSigners, availableUsers, signer }) => {

  const [name, setName] = useState(signer.name ?? "");
  const [rfc, setRfc] = useState(signer.rfc ?? "");
  const [errors, setErrors] = useState({ name: "", rfc: "" })

  const restartErrors = () => setErrors({ name: "", rfc: "" })
  const addError = (type, value) => setErrors(prev => ({ ...prev, [type]: value }))

  const handleSubmit = () => {
    let existErrors = false;
    if (!name && !errors.name) {
      existErrors = true
      setErrors(prev => ({ ...prev, name: 'El nombre del usuario es requerido' }))
    }

    if (name) {
      if (name > 50) {
        existErrors = true
        setErrors(prev => ({ ...prev, name: 'El nombre del usuario no puede ser mayor a 100 caracteres' }))
      }
    }

    if (rfc && !errors.rfc) {
      const rfcReg = new RegExp(/^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{0,3})$/i);

      if (!rfcReg.test(rfc)) {
        existErrors = true
        setErrors(prev => ({ ...prev, rfc: "RFC inválido" }))
      }
    }

    if (existErrors) return

    restartErrors()

    submitActions({
      name: name.trim(),
      rfc: rfc.trim() === '' ? null : rfc.trim(),
    })
  }

  const validateName = () => {
    const query = el => el.name?.trim().toLowerCase() === name.trim().toLowerCase();
    const existInSigners = selectedSigners.filter(el => !el.temporal).find(query);
    const existInTemporalSigners = selectedSigners.filter(el => el.temporal).find(query);
    const existInUsers = availableUsers.find(query);

    if (name === "") addError("name", "El nombre del usuario es requerido.")
    else if (!!existInSigners) addError("name", "Este nombre ya está registrado en un usuario catalogado en la plataforma y participa como firmante.")
    else if (!!existInUsers) addError("name", "Este nombre ya está registrado en un usuario catalogado en la plataforma.")
    else if (!!existInTemporalSigners) addError("name", "Este nombre ya está registrado en un usuario como firmante.")
    else addError("name", "")
  }

  const validateRFC = () => {
    const query = el => el?.rfc === rfc;
    const rfcReg = new RegExp(/^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{0,3})$/i);
    const existInSigners = selectedSigners.filter(el => !el.temporal).find(query);
    const existInTemporalSigners = selectedSigners.filter(el => el.temporal).find(query);
    const existInUsers = availableUsers.find(query);

    if (rfc === "") addError("rfc", "")
    else if (!rfcReg.test(rfc)) addError("rfc", "RFC invalido")
    else if (!!existInSigners) addError("rfc", "Este RFC ya está registrado en un usuario catalogado en la plataforma y participa como firmante.")
    else if (!!existInUsers) addError("rfc", "Este RFC ya está registrado en un usuario catalogado en la plataforma.")
    else if (!!existInTemporalSigners) addError("rfc", "Este RFC ya está registrado en un usuario como firmante.")
    else addError("rfc", "")
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Usuario Externo</DialogTitle>
      <DialogContent style={{ paddingTop: "0px", marginTop: "-16px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextFieldNative
              label="Nombre"
              value={name}
              message={errors.name}
              onChange={(e) => setName(e)}
              onBlur={validateName}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldNative
              label="Email"
              value={signer.email}
              type="email"
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldNative
              label="RFC (opcional)"
              value={rfc}
              message={errors.rfc}
              onChange={(e) => setRfc(e)}
              onBlur={validateRFC}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end" }}>
        <Button
          className="font-color mr-2"
          variant="outlined"
          onClick={() => {
            restartErrors()
            onCancel();
          }}
          disabled={false}
        >
          Cancelar
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={!!Object.values(errors).find(el => el)}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FormCompleteUserInfo