import React from 'react';
import { Button, Grid, makeStyles, Typography, useTheme } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles((theme) => ({

}));

const SignerCard = ({
  name,
  userId,
  userCertificate,
  onGenerateTag,
  check,
  loading,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Grid item container xs>
      <Grid
        item
        container
        style={{ backgroundColor: "#FFFF", marginRight: "5px" }}
        xs
        justifyContent='flex-start'
        alignItems='center'
      >
        {
          check &&
          <Grid item style={{ display: "flex" }}>
            <CheckCircleOutlineIcon fontSize='small' style={{ color: "#25e125" }} />
          </Grid>
        }
        <Grid item >
          <Typography
            title={name}
            variant="subtitle1"
            color={theme.palette.type === 'dark' ? 'initial' : 'primary'}
            style={{ paddingLeft: "5px" }}
          >
            {name.length > 28 ? name.split('').slice(0, 25).join('') + '...' : name}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Button
          size='small'
          variant="contained"
          onClick={() => onGenerateTag(name, userId, userCertificate)}
          color="primary"
          disabled={loading}
        >
          Etiqueta
        </Button>
      </Grid>

    </Grid>
  );
};



export default SignerCard;
