import React, { useEffect } from 'react'
import { Button, alpha, makeStyles } from '@material-ui/core';
import SignerCard from "./SignerCard";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '100%',
    height: "90vh",
    flexShrink: 0,
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    overflowX: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  drawerContainer: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    padding: "10px",
    gap: "10px"
  },
  saveButton: {
    padding: "15px",
  }
}));

const SideMenuSigners = (props) => {
  const {
    signers,
    saveStickers,
    handleGenerateTag,
    allDocumentsReady,
    loading
  } = props;
  const classes = useStyles();

  const handleClick = () => {
    saveStickers(allDocumentsReady)
  }
  return (
    <div
      className={classes.drawer}
    >
      <div className={classes.drawerContainer}>
        {signers.length > 0 && signers.map((data, index) => (
          <SignerCard
            key={data.id}
            name={data.name}
            userId={data.id}
            userCertificate={data.userCertificate}
            onGenerateTag={handleGenerateTag}
            check={data.check ?? false}
            loading={!!loading}
          />
        ))}
      </div>
      <div className={classes.saveButton}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={handleClick}
          disabled={loading}
        >
          {allDocumentsReady ? "Finalizar" : "Guardar"}
        </Button>
      </div>
    </div>
  )
}

export default SideMenuSigners