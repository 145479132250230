import React, { useEffect } from "react";
import { useState } from "react";
import {
  Dialog,
  Grid,
  Button,
  makeStyles,
  DialogContent,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  IconButton
} from "@material-ui/core";
import CancelIcon from '@material-ui/icons/Cancel';
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import moment from "moment";
import { TextFieldNative } from "../../fields/text-field-native";
import { SelectedUsersSimbols } from "./SelectedUsersSimbols";
import { useDispatch, useSelector } from "react-redux";
import { activityActions } from "../../../../../../core/actions/activity.actions";
import { DocumentsHttp } from "../../../../../../core/http/documents.http";
import { Alert } from "axeleratum-sgc-frontend-library";
import { SignatureNotificationConfig } from "./SignatureNotificationConfig";
import { CheckBox } from "./CheckBox";
import { getCurrentUser } from "../../../../../../core/helpers";
import { tokenExpired } from "../../../../../../core/utils";
import { authActions } from "../../../../../../core/actions";
import { createSet, getSetSigners } from "../../../../../../core/http/functionRequests/signature.http";
import { DraggableList } from "../../../../../draggableList/DraggableList";
import { SortableItem } from "../../../../../draggableList/SortableItem";
import { PopUpReleasers } from "../../../../../activities/groupSignature/PopUpReleasers";
import { PopUpOrderSigners } from "../../../../../activities/groupSignature/PopUpOrderSigners";
import PopUpNoSignersSelect from "../../../../../activities/groupSignature/PopUpNoSignersSelect";
import "./form-signature-activity.scss"

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: '0px',
    overflow: 'hidden',
  },
  container: {
    padding: "15px",
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 'inherit'
  },
  accionsContainer: {
    display: 'flex',
    alignItems: "flex-end",
    flexDirection: 'column',
    width: 'inherit',
    gap: "5px",
  }
}));

export const SendGroupSign = ({ open, documents, onCancel, onSubmit }) => {

  const dispatch = useDispatch();
  const classes = useStyles();

  const params = useParams();
  const location = useLocation();

  const [name, setName] = useState("");

  const [path, setPath] = useState(null);
  const [load, setLoad] = useState(false);
  const [message, setMessage] = useState("");
  const [vaultId, setVaultId] = useState(null);
  const [releasers, setReleasers] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [openUsers, setOpenUsers] = useState(false);
  const [hasNom151, setHasNom151] = useState(false);
  const [usersToSign, setUsersToSign] = useState([]);
  const [checkRelease, setCheckRelease] = useState(true);
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [typeMessage, setTypeMessage] = useState("error");
  const [orderedSigners, setOrderedSigners] = useState(false);
  const [documentsList, setDocumentsList] = useState(documents);
  const [openOrderSigners, setOpenOrderSigners] = useState(false);
  const [notificationsConfig, setNotificationsConfig] = useState({});
  const [openSignNotification, setOpenSignNotification] = useState(false);

  const handleCancel = () => {
    onCancel();
  }

  const handleSaveNotifications = (body) => {
    setNotificationsConfig(body)
    setOpenSignNotification(false);
  }

  const requestCreateSet = async (body) => {
    try {
      await createSet(body);
      setOpenAlert(false);
      setMessage("Actividad creada");
      setTypeMessage("success");
      onSubmit();
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        dispatch(authActions.userLoggedOut())
      }
      const msg = error.response.data.error
      setMessage(msg ?? "Error en el servidor")
      setTypeMessage("error")
      setOpenAlert(true);
    } finally {
      setLoad(false)
    }
  }
  const handleSubmit = () => {
    setLoad(true);

    const { monthsExpirationDefault } = getCurrentUser()
    const threeMonths = moment().add(monthsExpirationDefault ?? 3, 'months').format("YYYY-MM-DD");

    const body = {
      groupName: name,
      path,
      vaultId,
      releaseBy: releasers.map(el => el.id),
      orderSigners: usersToSign,
      orderDocuments: documentsList,
      date: moment(new Date()).format("YYYY-MM-DD"),
      hour: moment(new Date()).format("HH:MM"),
      hasNom151,
      notifyExpiringDay: notificationsConfig?.notifyExpiringDay ?? true,
      block_expired: notificationsConfig?.block_expired ?? true,
      subject: notificationsConfig?.subject,
      message: notificationsConfig?.message,
      remind_every: notificationsConfig?.remind_every,
      expiration_date: notificationsConfig?.expiration_date ? notificationsConfig.expiration_date : threeMonths,
      offset: new Date().getTimezoneOffset(),
      ordered: orderedSigners
    }

    requestCreateSet(body)
  }

  const handleSetReleasers = (users) => {
    setReleasers(users)
    setOpenUsers(false)
  }

  const handleCheckReleasers = () => {
    setCheckRelease(prev => !prev)
    setReleasers([])
  }

  const handleSetOrderSigners = (newOrder) => {
    setUsersToSign(newOrder)
    setOrderedSigners(true)
    setOpenOrderSigners(false)
  }

  const handleChangeGroupName = (e) => {
    setName(e)
    setNotificationsConfig(prev => ({
      ...prev,
      subject: null,
      message: null
    }))
  }

  useEffect(() => {
    const { onlyNom151, hasNom151, signatureWithNomDefault } = getCurrentUser();
    if (onlyNom151) {
      setShowCheckBox(false);
      setHasNom151(true);
    } else if (!onlyNom151 && !hasNom151) {
      setShowCheckBox(false);
      setHasNom151(false);
    } else if (!onlyNom151 && hasNom151) {
      setHasNom151(signatureWithNomDefault);
      setShowCheckBox(true);
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    const { search } = location;
    const pathFolder = new URLSearchParams(search).get('p');
    setPath(pathFolder);
    setVaultId(params.vaultId);

    const requestSignersPreparation = async (vaultId, path) => {
      setLoad(true);
      try {
        const search = await getSetSigners(vaultId, { path }, signal)
        setUsersToSign(search)
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          dispatch(authActions.userLoggedOut())
        }
        setTypeMessage("error")
        setMessage("Error obteniendo informacion de la preparación")
        setOpenAlert(true)
      } finally {
        setLoad(false);
      }
    }

    requestSignersPreparation(params.vaultId, pathFolder)

    return () => {
      controller.abort();
    }
  }, []);

  return (
    <>
      <Dialog open={open} id="Modal-Create-Signature-Activity">
        <DialogContent
          className={classes.dialogContent}
          id="Create-Signature-DialogContent">
          <Grid
            container
            spacing={1}
            className={classes.container}
            id="Create-Signature-Container-Grid">
            <Grid item xs={12} style={{ marginBottom: "15px" }}>
              <h3 style={{ margin: "2px" }}>{"Enviar a Firma en Grupo"}</h3>
            </Grid>
            <Grid item xs={9} style={{ overflow: "auto", maxHeight: "190px" }}>
              <List
                dense
                style={{ padding: 0 }}
              >
                <DraggableList
                  items={documentsList}
                  setItems={(newList) => setDocumentsList(newList)}
                >
                  {
                    documentsList.map((doc, index) => {
                      const itemObj = {
                        primary: doc.name + doc.extension
                      }
                      if ((doc.name + doc.extension).length > 67) {
                        itemObj.title = itemObj.primary;
                        itemObj.primary = itemObj.primary.slice(0, 64) + "...";
                      }

                      return (
                        <SortableItem
                          key={doc.id}
                          id={doc.id}
                          styles={{ paddingLeft: 0 }}
                        >
                          <span
                            style={{ padding: '0px 10px 0px 0', color: "#5C7BF0", fontWeight: "bold" }}
                          >
                            {index + 1}
                          </span>
                          <ListItemText {...itemObj} />
                        </SortableItem>
                      )
                    })
                  }
                </DraggableList>
              </List>
            </Grid>

            <Grid container item xs={3} direction="column">
              <Grid container item xs>
                <Grid item xs>
                  <CheckBox
                    label={"Liberar"}
                    checked={checkRelease}
                    setChecked={handleCheckReleasers}
                  />
                </Grid>
              </Grid>

              {showCheckBox && (
                <Grid container item xs>
                  <Grid item>
                    <CheckBox
                      label={"Con NOM-151"}
                      checked={hasNom151}
                      setChecked={() => setHasNom151((state) => !state)}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextFieldNative
                label="Nombre del Grupo"
                value={name}
                required
                onChange={handleChangeGroupName}
              />
            </Grid>
            <Grid item xs={12}>
              {load && (
                <div className="col-md-12 text-center">
                  <strong className="mb-2">
                    Procesando, espere por favor
                  </strong>
                  <LinearProgress
                    className="primary-color-bar"
                    color="primary"
                  />
                </div>
              )}
              {!load && (
                <div className={classes.accionsContainer}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <SelectedUsersSimbols users={releasers} />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setOpenUsers(true)}
                      disabled={load || !checkRelease}
                    >
                      Quien libera
                    </Button>
                  </div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    {
                      orderedSigners && (<>
                        <IconButton
                          title="Quitar orden"
                          size="small"
                          onClick={() => setOrderedSigners(false)}
                        >
                          <CancelIcon color="error" />
                        </IconButton>
                      </>)
                    }
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setOpenOrderSigners(true)}
                      disabled={load}
                    >
                      Orden firmantes (opcional)
                    </Button>
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenSignNotification(true)}
                    disabled={load || name.trim() === ""}>
                    Notificación y recordatorios (opcional)
                  </Button>
                </div>
              )}
            </Grid>

            <Grid item xs={12}>
              <div className={classes.buttonsContainer}>
                <Button
                  className="font-color mr-2"
                  variant="outlined"
                  onClick={handleCancel}
                  disabled={load}>
                  Cancelar
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={
                    load ||
                    name.trim() === "" ||
                    (checkRelease && releasers.length === 0)
                  }>
                  Enviar
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {openUsers && (
        <PopUpReleasers
          open={openUsers}
          vaultId={vaultId}
          selected={releasers}
          onCancel={() => setOpenUsers(false)}
          onSubmit={handleSetReleasers}
        />
      )}

      {
        openOrderSigners && (
          <PopUpOrderSigners
            open={openOrderSigners}
            selected={usersToSign}
            onCancel={() => setOpenOrderSigners(false)}
            onSubmit={handleSetOrderSigners}
          />
        )
      }

      {openSignNotification && (
        <SignatureNotificationConfig
          open={openSignNotification}
          onSave={(data) => handleSaveNotifications(data)}
          onCancel={() => setOpenSignNotification(false)}
          groupName={name}
          prev={notificationsConfig}
        />
      )}

      <Alert
        open={openAlert}
        title={message}
        onConfirm={() => {
          handleCancel();
        }}
        type={typeMessage}
      />
    </>
  )
}
