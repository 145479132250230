import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { TextFieldNative } from '../../../control/calendar/forms/fields/text-field-native'


const FormTemporalUser = ({
  submitActions, onCancel, open, availableUsers, usersSelected
}) => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({ name: "", email: "" })

  const restartErrors = () => setErrors({ name: "", email: "" })
  const addError = (type, value) => setErrors(prev => ({ ...prev, [type]: value }))

  const handleSubmit = () => {
    let existErrors = false;
    if (name) {
      if (name > 50) {
        existErrors = true
        setErrors(prev => ({ ...prev, name: 'El nombre del usuario no puede ser mayor a 100 caracteres' }))
      }
    }

    if (!email && !errors.email) {
      existErrors = true
      setErrors(prev => ({ ...prev, email: 'El email es requerido' }))
    } else {
      const emailReg = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
      if (!emailReg.test(email)) {
        existErrors = true
        setErrors(prev => ({ ...prev, email: "Email inválido" }))
      }
    }

    if (existErrors) return

    restartErrors()

    const id = uuidv4();
    submitActions({
      id,
      name: name.trim(),
      email: email.trim().toLowerCase(),
      temporal: true,
    })
  }

  const validateName = () => {
    const query = el => el.name?.trim().toLowerCase() === name.trim().toLowerCase();

    const existInSelected = usersSelected.find(query);
    const existInUsers = availableUsers.find(query);

    if (!!existInSelected || !!existInUsers) {
      addError("name", "Este nombre ya está registrado en un usuario que participa como destinatario.")
    } else {
      addError("name", "")
    }
  }

  const validateEmail = () => {
    const query = el => el.email.toLowerCase() === email.toLowerCase();
    const emailReg = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
    const existInSelected = usersSelected.find(query);
    const existInUsers = availableUsers.find(query);

    if (email === "") {
      addError("email", "El correo es requerido.")
    }
    else if (!emailReg.test(email)) {
      addError("email", "Correo inválido")
    }
    else if (!!existInSelected || !!existInUsers) {
      addError("email", "Este correo ya está registrado en un usuario que participa como destinatario.")
    }
    else {
      addError("email", "")
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Usuario Externo</DialogTitle>
      <DialogContent style={{ paddingTop: "0px", marginTop: "-16px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextFieldNative
              label="Nombre"
              value={name}
              message={errors.name}
              onChange={(e) => setName(e)}
              onBlur={validateName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldNative
              label="Email*"
              value={email}
              type="email"
              message={errors.email}
              onChange={(e) => setEmail(e)}
              onBlur={validateEmail}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end" }}>
        <Button
          className="font-color mr-2"
          variant="outlined"
          onClick={() => {
            restartErrors()
            onCancel();
          }}
          disabled={false}
        >
          Cancelar
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={!!Object.values(errors).find(el => el)}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default FormTemporalUser