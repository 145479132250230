
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { CardUserFn } from "../../control/calendar/popup/CardUserFn";
import { UserHttp } from "../../../core/http/user.http";
import { tokenExpired } from "../../../core/utils";
import { authActions } from "../../../core/actions";
import AddMultipleNoSigners from "./AddMultipleNoSigners";
import cancelationActions from "../../../core/actions/cancelation.actions";
import "./popup-user.scss";


const PopUpNosigners = ({ open, onCancel, onSubmit, labelType = "external", documentId }) => {
  const dispatch = useDispatch();

  const usersSelected = useSelector(state => state.cancelationReducer.usersNotToSign);
  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);

  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = () => {
    onSubmit && onSubmit(usersSelected);
  }

  const handleCancel = () => {
    onCancel();
  }

  useEffect(() => {

    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }


    const userHttp = new UserHttp();
    setLoad(true);
    userHttp.findAllUsersByDocumentId(
      documentId,
      "external",
      (resp) => {
        const sortUsersName = (x, y) => {
          return x.name.localeCompare(y.name);
        };
        let s = resp.sort(sortUsersName);
        dispatch(cancelationActions.saveAllUsers(s));
        setLoad(false);
      },
      (error) => {
        setLoad(false);
        setError(true);
      }
    );
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog className="popup-user" open={open}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: "10px" }}>
        <DialogTitle>Destinatarios seleccionados</DialogTitle>
        {
          !load && (
            <AddMultipleNoSigners />
          )
        }
      </div>

      {error && (
        <DialogContent>
          <Alert severity="error">
            Hubo un problema al cargar lo usuarios inténtalo mas tarde
          </Alert>
        </DialogContent>
      )}

      {load ? (
        <DialogContent className="text-center">
          <CircularProgress />
        </DialogContent>
      ) : (
        <>
          {!error && (
            <>
              <DialogContent className="card-zone">
                <Grid
                  container
                  spacing={1}
                  direction="column"
                >
                  {usersSelected.map((item) => (
                    <CardUserFn
                      key={item.userId}
                      labelType={labelType}
                      onlyShow={false}
                      id={item.userId}
                      name={item.name}
                      email={item.email}
                      color={item.color}
                      type={item.type}
                      rfc={item.rfc}
                      useRFC={item.hasRFC}
                      onChange={() => { }}
                      disableVerification
                    />
                  ))}
                </Grid>

                {usersSelected.length === 0 && (
                  <div className="text-center mt-2">
                    No hay usuarios seleccionados
                  </div>
                )}
              </DialogContent>
            </>
          )}
        </>
      )}
      <div>
        <DialogActions>


          <Button
            className="primary-text"
            color="primary"
            variant="outlined"
            onClick={() => handleCancel()}
          >
            Cancelar
          </Button>

          <Button
            color="primary"
            variant="contained"
            disabled={usersSelected.length === 0}
            onClick={() => handleSubmit()}
          >
            Aceptar
          </Button>

        </DialogActions>
      </div>
    </Dialog>
  );
}

export default PopUpNosigners