import { authHeader, axiosSignatureRequest } from "../../../configs/axios.config"
import { handleErrorAxios } from "../../helpers"


export const getAvailableSignatures = async () => {
  try {
    const { data } = await axiosSignatureRequest({
      url: "/available/documents",
      headers: authHeader()
    });

    return data;
  } catch (error) {
    throw error
  }
}

export const downloadScannedCopy = async (body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: "/download/scanned-document",
      method: 'post',
      headers: authHeader(),
      responseType: 'arraybuffer',
      data: body
    });

    return data;
  } catch (error) {
    throw error
  }
}

export const sendScannedCopy = async (body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: "/send/scanned-document",
      method: 'post',
      headers: authHeader(),
      data: body
    });

    return data;
  } catch (error) {
    throw error
  }
}

export const getClientConfiguration = async (documentId, type = "fim") => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/${documentId}/invitation-email/configuration?type=${type}`,
      headers: authHeader()
    });
    return data;
  } catch (error) {
    throw error
  }
}

export const cancelSignatureProccess = async (id, body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/${id}/cancel`,
      method: 'post',
      headers: authHeader(),
      data: body,
    });
    return data;
  } catch (error) {
    throw error
  }
}

export const updateSigner = async (body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/signer/update`,
      method: 'put',
      headers: authHeader(),
      data: body,
    })

    return data;
  } catch (error) {
    throw error
  }
}

export const sendSignBlock = async (body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/send/block-signature/email`,
      method: 'post',
      headers: authHeader(),
      data: body,
    })
    return data;
  } catch (error) {

    throw error
  }
}

export const editExpirationDate = async (activityId, body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/${activityId}/update/expiration_date`,
      method: 'put',
      headers: authHeader(),
      data: body,
    })
    return data;
  } catch (error) {

    throw error
  }
}

export const editExternalParticipants = async (documentActivityId, body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/${documentActivityId}/update/externalParticipants`,
      method: 'put',
      headers: authHeader(),
      data: body,
    })
    return data;
  } catch (error) {

    throw error
  }
}


export const createEndorseActivity = async (documentId, body, flag) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/endorse/${documentId}`,
      method: 'POST',
      headers: authHeader(),
      data: body,
      params: { q: flag }
    })
    return data;
  } catch (error) {

    throw error
  }
}

export const getAllStickers = async (documentId) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/endorse/${documentId}/stickers`,
    })
    return data;
  } catch (error) {

    throw error
  }
}

export const getLastSignersVerified = async (documentId) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/endorse/${documentId}/signers`,
    })
    return data;
  } catch (error) {

    throw error
  }
}

export const createSignActivity = async (documentId, body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/${documentId}/create`,
      method: 'POST',
      headers: authHeader(),
      data: body,
    })
    return data;
  } catch (error) {
    throw error
  }
}

export const addDocumentToGroupSign = async (documentId, body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/set/${documentId}/add`,
      method: 'POST',
      headers: authHeader(),
      data: body,
    })
    return data;
  } catch (error) {

    throw error
  }
}

export const getPreparationByDocumentId = async (documentId) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/set/${documentId}/detail-by-documentId`,
      headers: authHeader(),
    })
    return data;
  } catch (error) {

    throw error
  }
}

export const editPreparation = async (preparationId, body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/set/${preparationId}/edit`,
      method: 'PUT',
      headers: authHeader(),
      data: body,
    })
    return data;
  } catch (error) {

    throw error
  }
}

export const deletePreparation = async (preparationId) => {
  try {
    await axiosSignatureRequest({
      url: `/set/${preparationId}/delete`,
      method: 'DELETE',
      headers: authHeader(),
    })
  } catch (error) {

    throw error
  }
}

export const getNotificationSetConfig = async (groupName) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/set/configuration/invitation-email`,
      headers: authHeader(),
      params: {
        groupName
      }
    });
    return data;
  } catch (error) {

    throw error
  }
}

export const createSet = async (body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/set/create/from-path`,
      method: 'POST',
      headers: authHeader(),
      data: body
    });
    return data;
  } catch (error) {

    throw error
  }
}

export const getSetDetails = async (setId) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/set/${setId}/set-detail`,
      headers: authHeader(),
    });
    return data;
  } catch (error) {

    throw error
  }
}

export const releaseGroup = async (setId) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/set/${setId}/release-group`,
      headers: authHeader(),
    });
    return data;
  } catch (error) {

    throw error
  }
}

export const editReleasers = async (setId, body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/set/${setId}/edit-releasers`,
      method: "PUT",
      headers: authHeader(),
      data: body
    });
    return data;
  } catch (error) {

    throw error
  }
}

export const editNotifications = async (setId, body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/set/${setId}/edit-notifications`,
      method: "PUT",
      headers: authHeader(),
      data: body
    });
    return data;
  } catch (error) {

    throw error
  }
}

export const sendSetReminder = async (setId) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/set/${setId}/reminder`,
      headers: authHeader(),
    });
    return data;
  } catch (error) {
    throw error
  }
}

export const cancelSetFlow = async (setId, body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/set/${setId}/cancel`,
      method: "POST",
      headers: authHeader(),
      data: body
    });
    return data;
  } catch (error) {

    throw error
  }
}

export const getMultipleSignaturesLinks = async () => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/multiple-signature/action`,
      headers: authHeader(),
    });
    return data;
  } catch (error) {

    throw error
  }
}


export const saveOrModifySetSigners = async (body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/set/signers/insert`,
      headers: authHeader(),
      method: "POST",
      data: body
    });
    return data;
  } catch (error) {
    throw error
  }
}

export const saveOrModifySetNonSigners = async (body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/set/nonsigners/insert`,
      headers: authHeader(),
      method: "POST",
      data: body
    });
    return data;
  } catch (error) {
    throw error
  }
}

export const getSetSigners = async (vaultId, params, signal) => {
  try {
    const signalParam = {};
    if (signal) signalParam.signal = signal
    const { data } = await axiosSignatureRequest({
      url: `/set/${vaultId}/signers`,
      headers: authHeader(),
      params,
      ...signalParam
    });
    return data;
  } catch (error) {
    throw error
  }
}

export const getSetNonSigners = async (vaultId, path, signal) => {
  try {
    const signalParam = {};
    if (signal) signalParam.signal = signal
    const { data } = await axiosSignatureRequest({
      url: `/set/${vaultId}/nonsigners`,
      headers: authHeader(),
      params: {
        path
      },
      ...signalParam
    });
    return data;
  } catch (error) {
    throw error
  }
}

export const downloadSignaturesHistory = async (documentId) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/${documentId}/signatures/history/pdf`,
      responseType: 'blob',
      headers: authHeader(),
    })
    return data;
  } catch (error) {

    throw error
  }
}

export const saveOrModifyStickers = async (body) => {
  try {
    const { data } = await axiosSignatureRequest({
      url: `/set/stickers/save`,
      headers: authHeader(),
      method: "POST",
      data: body
    });
    return data;
  } catch (error) {
    throw error
  }
}

export const getPathStickersDocuments = async (vaultId, params, signal) => {
  try {
    const signalParam = {};
    if (signal) signalParam.signal = signal
    const { data } = await axiosSignatureRequest({
      url: `/set/${vaultId}/stickers`,
      headers: authHeader(),
      params,
      ...signalParam
    });
    return data;
  } catch (error) {
    throw error
  }
}


export const getPathGroups = async (vaultId, params, signal) => {
  try {
    const signalParam = {};
    if (signal) signalParam.signal = signal
    const { data } = await axiosSignatureRequest({
      url: `/set/${vaultId}/groups`,
      headers: authHeader(),
      params,
      ...signalParam
    });
    return data;
  } catch (error) {
    throw error
  }
}