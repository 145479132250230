
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Tabs,
  Tab,
  Backdrop,
  Box
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from 'uuid';
import Alert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { UserHttp } from "../../../../core/http/user.http";
import { authActions } from "../../../../core/actions";
import { UserSelector } from "./UserSelector";
import UserSelected from "./UserSelected";
import { AlertComponent } from "../../../AlertComponent";
// import "../popup-user.scss";
import { getSetNonSigners, saveOrModifySetNonSigners } from "../../../../core/http/functionRequests";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  textColorDisabled: {
    color: 'rgba(92, 123, 240, 0.6)'
  },
  tabPanel: {
    width: "100%",
    height: "330px",
    overflowY: "auto"
  },
  area: {
    fontSize: "13px",
    padding: "5px"
  },
  dialogContent: {
    paddingTop: 5
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}


const processSignerData = (text) => {
  const data = { email: null, name: null }
  const match = text.match(/["']?([^"'<]*)["']?\s*<([^>]*)>/);
  const emailReg = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);

  if (match) {
    data.name = match[1].trim();
    data.email = match[2].trim().toLowerCase().replace(/^["']|["']$/g, '');
  } else {
    data.email = text.trim().toLowerCase().replace(/^["']|["']$/g, '');
  }
  if (!emailReg.test(data.email ?? text)) {
    return { error: "Correo inválido", ...data }
  }

  return data;

}


const PopupNonSignersSet = ({ open, vaultId, path, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const currentuser = useSelector(state => state.authReducer.currentUser);

  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [usersSelected, setUsersSelected] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [openSelectUsers, setOpenSelectUsers] = useState(false);
  const [emailsText, setEmailsText] = useState("");
  const [textError, setTextError] = useState("");
  const [tabValue, setTabValue] = useState("1");
  const [alert, setAlert] = useState({ open: false, title: "", type: "error" })
  const [nonSignersDelete, setNonSignersDelete] = useState([])

  const resetAlert = () => setAlert({ open: false, title: "", type: "error" })
  const openAlert = (title, type = "error") => setAlert({ title, type, open: true })

  const handleConfirmAlert = () => {
    if (alert.type === "success") {
      onClose()
    }
    resetAlert()
  }

  const handleSubmit = async () => {

    const users = usersSelected.map(el => ({
      email: el.email,
      name: el.name,
      temporal: el.temporal,
      userId: el.id,
      vaultId,
      path,
      clientId: currentuser.client_id,
    }))

    const body = {
      vaultId,
      path,
      users,
      deleteUsers: nonSignersDelete.filter(el => el.delete)
    }

    setLoad(true)
    try {
      await saveOrModifySetNonSigners(body)
      openAlert("Destinatarios guardados", "success")
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        dispatch(authActions.userLoggedOut())
      }
      openAlert("Error guardando destinatarios")
    } finally {
      setLoad(false)
    }

  }

  const handleCancel = () => {
    onClose();
  }

  const handleChangeText = (e) => {
    if (textError.length > 0) {
      setTextError("")
    }
    setEmailsText(e.target.value);
  }

  const handleDeleteText = () => {
    setEmailsText("")
    setTextError("")
  }

  const handleSeparateEmails = () => {
    setLoad(true);
    const text = emailsText.trim();

    // Si solo es un correo
    if (!text.includes(";")) {
      const textData = processSignerData(text)
      const query = el => el.name.trim().toLowerCase() === textData.name.toLowerCase();

      setLoad(false)
      if (textData.error) {
        setTextError("Correo inválido.");
        return;
      }

      if (usersSelected.find(el => el.email.trim().toLowerCase() === textData.email)) {
        setEmailsText("")
        return
      }

      const existsInAvailables = availableUsers.find(el => el.email.trim().toLowerCase() === textData.email)
      if (existsInAvailables) {
        const allSelected = [...usersSelected, existsInAvailables].sort((a, b) => (a.email).localeCompare(b.email))
        setUsersSelected(allSelected)
      } else {
        if (textData.name) {
          const existsNameInAvailables = availableUsers.find(query)
          const existsNameInSelected = usersSelected.find(query)
          if (existsNameInAvailables) {
            setTextError("El nombre ya existe en un usuario catalogado.");
            return;
          }
          if (existsNameInSelected) {
            setTextError("El nombre ya existe en un usuario que participa como destinatario.");
            return;
          }
        }
        const allSelected = [
          ...usersSelected,
          {
            ...textData,
            id: uuidv4(),
            temporal: true,
          }
        ].sort((a, b) => (a.email).localeCompare(b.email))
        setUsersSelected(allSelected)
      }
      setEmailsText("")
      return
    }

    // Si son varios correos
    const separate = (text.split(";")).filter(el => el)
    const result = separate.reduce((acc, curr) => {
      const textData = processSignerData(curr);
      const query = el => el.name?.trim().toLowerCase() === textData.name?.toLowerCase();

      if (textData.error) {
        acc.error += ` Correo inválido: '${textData.email}'.`
        return acc;
      }

      if (acc.signers.find(el => el.email === textData.email)) {
        return acc
      }

      if (usersSelected.find(el => el.email === textData.email)) {
        return acc
      }

      const existsInAvailables = availableUsers.find(el => el.email === textData.email)

      if (existsInAvailables) {
        return { ...acc, signers: [...acc.signers, existsInAvailables] }
      }

      if (textData.name) {
        const existsNameInAvailables = availableUsers.find(query)
        const existsNameInSelected = usersSelected.find(query)
        const duplicateName = acc.signers.find(query)

        if (existsNameInAvailables) {
          acc.error += ` El nombre ya existe en un usuario catalogado: '${textData.name}'.`
          return acc;
        }
        if (existsNameInSelected) {
          acc.error += ` El nombre ya existe en un usuario que participa como destinatario: '${textData.name}'.`
          return acc;
        }
        if (duplicateName) {
          acc.error += ` Nombre repetido: '${textData.name}'.`
          return acc;
        }
      }

      return {
        ...acc, signers: [
          ...acc.signers,
          {
            ...textData,
            id: uuidv4(),
            temporal: true,
          }
        ]
      }
    }, { error: '', signers: [] })

    setLoad(false)

    if (result.error) {
      setTextError(result.error)
      return
    }
    const allSelected = [
      ...usersSelected,
      ...result.signers
    ].sort((a, b) => (a.email).localeCompare(b.email))
    setUsersSelected(allSelected)
    setEmailsText("")
  }

  const handleChangeTab = (e, value) => {
    setTabValue(value)
  }

  const handleDeleteNonSigner = (userId) => {
    setNonSignersDelete(prev => prev.map(el => {
      if (el.id === userId) {
        el.delete = true
      }
      return el
    }))
    setUsersSelected(prev => prev.filter(el => el.id !== userId))
  }


  const handleChangeSigner = (id, key, value) => {
    setUsersSelected(prev => prev.map(signer => {
      if (signer.id === id) {
        signer[key] = value
      }
      return signer
    }))
  }

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const searchUsersByVault = async () => {
      setLoad(true);
      try {

        const nonSigners = await getSetNonSigners(vaultId, path, signal);
        setUsersSelected(nonSigners)
        setNonSignersDelete(nonSigners.map(el => ({ ...el, delete: false })))

        const userhttp = new UserHttp();
        const search = await userhttp.getActiveUsersByVault(vaultId)
        const filterUsers = search.filter(el => el.email !== "soporte@kasiasolutions.com")
        setAvailableUsers(filterUsers);
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          dispatch(authActions.userLoggedOut())
        }
        setError(true);
      } finally {
        setLoad(false);
      }
    }

    searchUsersByVault()
    return () => {
      controller.abort();
    }
  }, []);

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: "24px" }}>
          <DialogTitle style={{ fontWeight: "bold" }}>DESTINATARIOS</DialogTitle>
          {

            !load && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpenSelectUsers(true)}
              >
                Agregar
              </Button>
            )
          }
        </div>

        <DialogContent className={classes.dialogContent}>

          {error && (
            <Alert severity="error">
              Hubo un problema al cargar lo usuarios inténtalo mas tarde
            </Alert>
          )}

          <Grid container spacing={1}>

            <Grid item xs={12}>
              <TextField
                className={classes.area}
                fullWidth
                multiline
                maxRows={5}
                label="Texto"
                variant="outlined"
                value={emailsText}
                onChange={handleChangeText}
                helperText={textError}
                error={textError.length > 0}
                disabled={load}
              />
            </Grid>

            <Grid item container>

              <Grid item>
                <Tabs
                  value={tabValue}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChangeTab}
                >
                  <Tab label={"USUARIOS CATALOGADOS"} value="1" classes={{ textColorPrimary: classes.textColorDisabled }} />
                  <Tab label={"EXTERNOS (1 sola vez)"} value="2" classes={{ textColorPrimary: classes.textColorDisabled }} />
                </Tabs>
              </Grid>

              <Grid item container justifyContent="flex-end" xs>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    style={{ marginRight: "10px" }}
                    onClick={handleDeleteText}
                  >
                    Borrar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleSeparateEmails}
                    disabled={emailsText.trim().length < 4}
                  >
                    Separar
                  </Button>
                </Grid>
              </Grid>


              <Grid item container spacing={0} xs={12}>
                <TabPanel value={tabValue} index="1" className={classes.tabPanel}>
                  {usersSelected.filter(el => !el.temporal).map((signer) => (
                    <UserSelected
                      key={signer.id}
                      onDelete={handleDeleteNonSigner}
                      signer={signer}
                      onChange={handleChangeSigner}
                    />
                  ))}
                </TabPanel>

                <TabPanel value={tabValue} index="2" className={classes.tabPanel}>
                  {usersSelected.filter(el => el.temporal).map((signer) => (
                    <UserSelected
                      key={signer.id}
                      toComplete={signer.temporal}
                      onDelete={handleDeleteNonSigner}
                      signer={signer}
                      onChange={handleChangeSigner}
                      availableUsers={availableUsers}
                      selectedSigners={usersSelected}
                    />
                  ))}
                </TabPanel>
              </Grid>
            </Grid>
          </Grid>

        </DialogContent>

        <div>
          <DialogActions>

            <Button
              className="primary-text"
              color="primary"
              variant="outlined"
              onClick={() => handleCancel()}
              disabled={load}
            >
              Cancelar
            </Button>

            <Button
              color="primary"
              variant="contained"
              onClick={() => handleSubmit()}
              disabled={load}
            >
              Guardar
            </Button>

          </DialogActions>
        </div>
        <Backdrop open={load} className={classes.backdrop}>
          <CircularProgress />
        </Backdrop>
      </Dialog >

      {
        openSelectUsers ? (
          <UserSelector
            open={openSelectUsers}
            users={availableUsers}
            selected={usersSelected}
            currentSigners={usersSelected}
            setUsers={setAvailableUsers}
            onSelect={setUsersSelected}
            onClose={() => setOpenSelectUsers(false)}
            allowExternal
          />
        ) : <></>
      }

      {
        alert.open ? (
          <AlertComponent
            open={alert.open}
            title={alert.title}
            type={alert.type}
            onConfirm={handleConfirmAlert}
          />
        ) : <></>
      }
    </>
  )
}

export default PopupNonSignersSet