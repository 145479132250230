import { useEffect, useState } from "react";
import {
  Button,
  Grid,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from '@material-ui/icons/Delete';
import FormCompleteUserInfo from "../../../team/users/NonSignerInfo";

const useStyles = makeStyles((theme) => ({
  name: {
    display: "block",
    whiteSpace: "nowrap",
    textOverflow: 'ellipsis',
    overflow: "hidden",
    width: "100%",
    paddingRight: '5px',
  },
  email: {
    display: "block",
    whiteSpace: "nowrap",
    textOverflow: 'ellipsis',
    overflow: "hidden",
    width: "100%",
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  selector: {
    fontSize: "13px",
    width: '100px'
  }
}));


const UserSelected = ({
  signer,
  onDelete,
  onChange,
  availableUsers = [],
  selectedUsers = [],
  toComplete = false,
}) => {

  const classes = useStyles();

  const [openComplete, setOpenComplete] = useState(false);


  const handleCompleteUser = (data) => {
    if (data.name !== signer.name) {
      onChange(signer.id, 'name', data.name)
    }
    setOpenComplete(false)
  }


  return (
    <>
      <Grid item container xs={12} spacing={1} alignItems="center">

        <Grid
          item
          xs={toComplete ? 5 : 6}
          className={classes.email}
        >
          <label title={signer.email}>
            {signer.email}
          </label>
        </Grid>

        <Grid
          item
          xs={toComplete ? 4 : 5}
          className={classes.name}
        >
          <label title={signer.name}>
            {signer.name}
          </label>
        </Grid>

        {
          toComplete ? (
            <Grid item xs={2}>
              <Button
                title="Completar"
                color="primary"
                variant="contained"
                onClick={() => setOpenComplete(true)}
                size="small"
                style={{ textSizeSmall: "10px" }}
              >
                comp
              </Button>
            </Grid>
          ) : <></>
        }


        <Grid item container xs={1} justifyContent="flex-end">
          <IconButton
            title={`Borrar ${signer.email}`}
            onClick={() => onDelete(signer.id)}
            style={{ padding: 0 }}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>

      </Grid>

      {
        openComplete ? (
          <FormCompleteUserInfo
            open={openComplete}
            signer={signer}
            onCancel={() => setOpenComplete(false)}
            selectedUsers={selectedUsers.filter(el => el.id !== signer.id)}
            availableUsers={availableUsers}
            submitActions={handleCompleteUser}
          />
        ) : <></>
      }

    </>
  )
}

export default UserSelected