import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Backdrop, Breadcrumbs, Button, CircularProgress, Grid, IconButton } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { makeStyles } from '@material-ui/styles';
import EventIcon from '@material-ui/icons/Event';
import { Link } from "react-router-dom";
import { useVault } from '../../hooks/useVault';
import { OptionsMenu } from './components/OptionsMenu';
import { menuAdd } from '../../core/utils/menuOptions';
import { Alert } from 'axeleratum-sgc-frontend-library';
import { FoldersHttp } from '../../core/http/folders.http';
import { DocumentsHttp } from '../../core/http/documents.http';
import { TagFilter } from './components/TagFilter';
import { tokenExpired } from '../../core/utils';
import { authActions } from '../../core/actions';
import { SearchBarVaults } from './components/SearchBarVaults';
import { FileSystem } from './FileSystem';
import Header from '../dashboard/header';
import DialogFormFolder from './dialog-form-folder';
import { SendGroupSign } from '../control/calendar/forms/new-activity/signature/SendGroupSign';
import DialogSaveDocuments from './DialogSaveDocuments';
import './vault.scss';
import { deleteFromStorageProvider, getSetSigners } from '../../core/http/functionRequests';
import SignatureSetStatus from '../activities/groupSignature/SignatureSetStatus';
import PopupSignersSet from '../activities/groupSignature/PopupSignersSet';
import StickersPosition from '../activities/groupSignature/StickersPosition/StickersPosition';
import PopupNonSignersSet from '../activities/groupSignature/NonSigners/PopupNonSignersSet';


const invalidFolderNames = [
  "templates",
  "plantillas",
  "libros corporativos",
  "trash",
];

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  listItem: {
    paddingBottom: "6px",
    paddingTop: "6px"
  }
}));


const VaultFilesFn = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);

  const [options, setOptions] = useState([]);
  const [isTrash, setIsTrash] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorSet, setAnchorSet] = useState(null);
  const [listLoader, setListLoader] = useState(false);
  const [signatureSet, setSignatureSet] = useState(null);
  const [createLoader, setCreateLoader] = useState(false);
  const [enableSendSet, setEnableSendSet] = useState(false);
  const [openDocuments, setOpenDocuments] = useState(false);
  const [openDialogSigners, setOpenDialogSigners] = useState(false);
  const [openDialogNewFolder, setOpenDialogNewFolder] = useState(false)
  const [openDialogNonSigners, setOpenDialogNonSigners] = useState(false);
  const [enableStickerPosition, setEnableStickerPosition] = useState(false);
  const [openDialogNewDocument, setOpenDialogNewDocument] = useState(false);
  const [openSignatureSetStatus, setOpenSignatureSetStatus] = useState(false);
  const [openDialogSendGroupSign, setOpenDialogSendGroupSign] = useState(false);
  const [alert, setAlert] = useState({ open: false, title: '', type: 'error' });

  const {
    sets,
    vault,
    addTag,
    loading,
    goToPath,
    documents,
    arrayPath,
    removeTag,
    filterTags,
    currentUser,
    openBackDrop,
    currentFolderId,
    setItemSelected,
    currentDocuments,
    enableDescription,
    requestFileSystem,
    searchElementLocal,
  } = useVault();

  const foldersHttp = new FoldersHttp();
  const documentsHttp = new DocumentsHttp();

  const handleClickBreadcrumb = (index) => {
    if (index === 0) {
      goToPath('')
    } else {
      const goTo = arrayPath.reduce((prev, curr, i) => {
        if (i <= index && i > 0) {
          return [...prev, curr]
        }
        return prev
      }, []).join('/');

      goToPath(goTo)
    }
  }

  const handleCloseMenu = () => {
    setAnchorEl(null)
  }

  const handleSaveFolder = (formData) => {
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    if (!formData.name || formData.name.trim().length === 0) {
      setAlert({
        open: true,
        title: "El nombre del folder es obligatorio",
        type: 'error'
      })

      return;
    }

    setOpenDialogNewFolder(false)
    setCreateLoader(true)
    const invalidName = invalidFolderNames.some(
      (name) => name === formData.name.toLowerCase()
    );

    const mensaje = "No se puede crear este forlder con la palabra reservada: " + formData.name;

    if (invalidName) {
      setCreateLoader(false)
      setAlert({
        open: true,
        title: mensaje,
        type: 'error'
      })
      return;
    }
    const path = arrayPath.reduce((prev, curr, i) => i === 0 ? prev : [...prev, curr], []).join('/')
    const objRequest = {
      folderId: !path ? vault.id : currentFolderId,
      isCompany: !path,
      name: formData.name,
    }
    if (path) {
      objRequest.path = path
    }

    foldersHttp.createFolder(
      vault.id,
      objRequest,
      (data) => {
        requestFileSystem()
        setCreateLoader(false)
        setAlert({
          open: true,
          title: "Se ha guardado correctamente",
          type: 'success'
        })
      },
      (error) => {
        setCreateLoader(false)
        setAlert({
          open: true,
          title: `${error.response.data
            ? error.response.data
            : "Ocurrió un error al guardar."
            }`,
          type: 'error'
        });
      }
    );
  };

  const handleClickMeuItem = (value) => {
    setAnchorEl(null);
    if (value === 'document') {
      setOpenDialogNewDocument(true)
    }
    if (value === 'directory') {
      setOpenDialogNewFolder(true);
    }
  }

  const handleConfirmAlert = () => {
    setAlert({ open: false, title: '', type: '' });
  }
  const openAlert = (title, type = "error") => setAlert({ open: true, title, type })

  const handleDocumentSelect = (doc) => {
    setItemSelected(doc)
  }

  const requestDeleteDocuments = async (documents) => {
    try {
      const promise = documents.map(document => {
        if (!!document) {
          return deleteFromStorageProvider(`${document.storedName}${document.extension}`)
        }
        return null
      })

      await Promise.allSettled(promise)
    } catch (error) {
      console.error(error);

    } finally {

    }
  }

  const createDocuments = (data) => {

    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    if (createLoader) {
      return
    }

    setOpenDialogNewDocument(false)
    setCreateLoader(true)

    const path = arrayPath.reduce((prev, curr, i) => i === 0 ? prev : [...prev, curr], []).join('/')

    const request = {
      ...data,
      path,
      vaultId: vault.id,
      owner: currentUser.userId
    }

    if (currentFolderId) {
      request.folderId = currentFolderId;
    }
    console.log(request);

    documentsHttp.saveDocuments(
      request,
      () => {
        requestFileSystem()
        setCreateLoader(false)
        setAlert({
          open: true,
          title: "Se ha guardado correctamente",
          type: 'success'
        });
      },
      () => {
        setCreateLoader(false)
        const message = "Ocurrió un error al guardar."
        requestDeleteDocuments(data.documents)
        setAlert({
          open: true,
          title: message,
          type: 'error'
        })
      }
    )
  };

  const handleOpenSetMenu = (e) => {
    setAnchorSet(e.currentTarget)
    setListLoader(true)
  }

  const handleOpenSetStatus = (set) => {
    setSignatureSet(set);
    setOpenSignatureSetStatus(true)
  }

  const handleCloseSignatureSet = () => {
    setSignatureSet(null);
    setOpenSignatureSetStatus(false)
  }

  const handleCloseStickerPosition = () => {
    setOpenDocuments(false)
    setListLoader(true)
  }

  const handleCloseSetSigners = () => {
    setOpenDialogSigners(false)
    setListLoader(true)
  }

  const handleCloseSetParticipants = () => {
    setOpenDialogNonSigners(false)
    setListLoader(true)
  }

  useEffect(() => {
    if (!listLoader) return
    const controller = new AbortController();
    const signal = controller.signal;

    const requests = async () => {
      const currentPath = arrayPath.reduce((prev, curr, i) => i === 0 ? prev : [...prev, curr], []).join('/')
      try {
        const { stickers, signers } = await getSetSigners(vault.id, { path: currentPath, include: "stickers" }, signal)
        const pdfDocuments = currentDocuments.filter(el => el.extension === ".pdf")
        const filterDocuments = pdfDocuments.filter(el => ["Preparar Revisión", "Aprobado"].includes(el.statusBpm))
        const documentsReady = filterDocuments.length === Object.keys(stickers).length
        const signersReady = filterDocuments.every(doc => stickers[doc.id]?.length > 0) &&
          signers.every(signer =>
            Object.values(stickers).flat()
              .some(sticker => sticker.userId === signer.id));

        setEnableSendSet(documentsReady && filterDocuments.length > 0 && signersReady)
        setEnableStickerPosition(signers.length > 0)
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          dispatch(authActions.userLoggedOut())
        }
      } finally {
        setListLoader(false)
      }
    }
    requests()

    return () => {
      controller.abort();
    }
  }, [listLoader])


  useEffect(() => {
    setIsTrash(arrayPath[1] === "Trash" ?? false)
  }, [arrayPath])

  useEffect(() => {
    if (!isTrash) {
      setOptions(menuAdd)
    }
  }, [isTrash])

  return (
    <>
      <Header
        titleRight={'Bóveda'}
        actions={[
          <IconButton variant='outlined'>
            <NotificationsIcon color='primary' />
          </IconButton>,
          <IconButton variant='outlined'>
            <EventIcon color='primary' />
          </IconButton>,
        ]}
      />

      {
        !loading && (
          <>
            <Grid container alignItems='center' spacing={3}>
              <SearchBarVaults
                xs={12}
                md={8}
                lg={8}
                searchlocal={searchElementLocal}
                includedescription={enableDescription}
                documentselect={handleDocumentSelect}
              />
              <Grid
                item
                container
                xs={6}
                md={4}
                lg={4}
                alignContent='center'
                justifyContent='flex-end'
                spacing={3}
                direction='row'
              >
                {!isTrash && (
                  <Grid item container spacing={3} justifyContent='flex-end'>
                    {
                      currentUser.allowDocumentSet &&
                        (documents.filter(el => el.extension === ".pdf" && ["Preparar Revisión", "Aprobado"].includes(el.statusBpm))
                          .length > 1 || sets.length > 0) ? (
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpenSetMenu}
                          >
                            Enviar en grupo
                          </Button>
                        </Grid>
                      ) : <></>
                    }

                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(evt) => {
                          setAnchorEl(evt.currentTarget)
                        }}
                      >
                        +&nbsp;Nuevo
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid container className="TagsFilter" >
              {
                vault?.tags.sort((a, b) => a.localeCompare(b)).map((tag, index) => (
                  <TagFilter
                    label={`${tag}`}
                    key={index}
                    onClick={() => {
                      addTag(tag)
                    }}
                    onClear={() => {
                      removeTag(tag)
                    }}
                    tagSelected={filterTags.includes(tag)}
                  />
                ))
              }
            </Grid>
          </>
        )
      }

      <Grid container className="Breadcrumbs">
        <Breadcrumbs

          separator={<ArrowForwardIosIcon />}
          aria-label="breadcrumb"
          style={{ fontSize: "1rem" }}
        >
          {
            vault ? (
              <Link color="inherit" to="/dashboard/vault">
                Bóveda
              </Link>

            ) : (
              <span>Bóvedas</span>
            )
          }
          {
            arrayPath.map((item, index) => (
              <span
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleClickBreadcrumb(index);
                }}
              >
                {item}
              </span>
            ))
          }
        </Breadcrumbs>
      </Grid>

      <FileSystem />

      {
        openDialogNewFolder && (
          <DialogFormFolder
            openDialog={openDialogNewFolder}
            submitActions={(formData) => handleSaveFolder(formData)}
            onCancel={() => setOpenDialogNewFolder(false)}
            initialValues={{}}
            typeForm={'create'}
          />
        )
      }

      {
        openDialogNewDocument && (
          <DialogSaveDocuments
            openDialog={openDialogNewDocument}
            submitActions={(formData) => createDocuments(formData)}
            onCancel={() => setOpenDialogNewDocument(false)}
            tagsSelect={vault.tags.map((tag) => ({ label: tag, value: tag }))}
            currentDocuments={documents}
          />
        )
      }

      { //TODO: ultimo paso para enviar a firma en grupo
        openDialogSendGroupSign && (
          <SendGroupSign
            open={openDialogSendGroupSign}
            documents={(currentDocuments.filter(doc => doc.extension === ".pdf")).filter(el => el.statusBpm === "Preparar Revisión")}
            onCancel={() => setOpenDialogSendGroupSign(false)}
            onSubmit={() => {
              setOpenDialogSendGroupSign(false);
              setAnchorSet(null)
              openAlert("Grupo de documentos enviado a firma correctamente", "success")
              requestFileSystem()
            }}
          />
        )
      }

      {
        openDialogSigners && (
          <PopupSignersSet
            open={openDialogSigners}
            onClose={handleCloseSetSigners}
            vaultId={vault.id}
            path={arrayPath.reduce((prev, curr, i) => i === 0 ? prev : [...prev, curr], []).join('/')}
          />
        )
      }

      {
        openDialogNonSigners && (
          <PopupNonSignersSet
            open={openDialogNonSigners}
            onClose={handleCloseSetParticipants}
            vaultId={vault.id}
            path={arrayPath.reduce((prev, curr, i) => i === 0 ? prev : [...prev, curr], []).join('/')}
          />
        )
      }


      {vault &&
        <StickersPosition
          open={openDocuments}
          onClose={handleCloseStickerPosition}
          documents={currentDocuments.filter(el => el.extension === ".pdf" && ["Preparar Revisión", "Aprobado"].includes(el.statusBpm))}
          vaultId={vault.id}
          path={arrayPath.reduce((prev, curr, i) => i === 0 ? prev : [...prev, curr], []).join('/')}
        />
      }


      <Popover
        id='menu-firmas-en-grupo'
        open={Boolean(anchorSet)}
        onClose={() => setAnchorSet(null)}
        anchorEl={anchorSet}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          <ListItem
            className={classes.listItem}
            disabled={currentDocuments.filter(el => el.extension === ".pdf" && ["Preparar Revisión", "Aprobado"].includes(el.statusBpm)).length === 0}
            button
            onClick={() => setOpenDialogSigners(true)}
          >
            <ListItemText primary="Firmantes" />
          </ListItem>
          <ListItem
            className={classes.listItem}
            disabled={currentDocuments.filter(el =>
              ["Preparar Revisión", "Aprobado"].includes(el.statusBpm)).length === 0 &&
              sets.filter(set => set.pending).length === 0
            }
            button
            onClick={() => setOpenDialogNonSigners(true)}
          >
            <ListItemText primary="Destinatarios no Firmantes" />
          </ListItem>
          <ListItem
            className={classes.listItem}
            disabled={!(currentDocuments.filter(el => ["Preparar Revisión", "Aprobado"].includes(el.statusBpm)).length > 0 && enableStickerPosition)}
            button
            onClick={() => setOpenDocuments(true)}
          >
            <ListItemText primary="Posicionar Etiquetas" />
          </ListItem>
          <ListItem
            className={classes.listItem}
            button
            onClick={() => setOpenDialogSendGroupSign(true)}
            disabled={!enableSendSet}
          >
            <ListItemText primary="Enviar a Firma" />
          </ListItem>

          {
            listLoader && <ListItem style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </ListItem>
          }

          {
            !listLoader && sets.map(el => (
              <ListItem
                className={classes.listItem}
                key={el.id}
                button
                onClick={() => handleOpenSetStatus(el)}
              >
                <ListItemText primary={el.name} />
              </ListItem>
            ))
          }
        </List>
      </Popover >

      {
        signatureSet && openSignatureSetStatus
          ? (
            <SignatureSetStatus
              open={openSignatureSetStatus}
              setId={signatureSet.id}
              name={signatureSet.name}
              onClose={handleCloseSignatureSet}
              currentUser={currentUser}
            />
          ) : <></>
      }

      <OptionsMenu
        options={options}
        anchorEl={anchorEl}
        handleCloseMenu={handleCloseMenu}
        handleClickMeuItem={handleClickMeuItem}
      />

      <Alert
        open={alert.open}
        title={alert.title}
        type={alert.type}
        onConfirm={handleConfirmAlert}
      />


      <Backdrop open={openBackDrop || createLoader} className={classes.backdrop}>
        <CircularProgress />
      </Backdrop>



    </>
  )
}

export default VaultFilesFn