import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { Grid } from '@material-ui/core';
import { useEffect, useState } from 'react';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'static',
  },
  selector: {
    backgroundColor: "#FFFF",
    margin: theme.spacing(1),
    borderRadius: 4,
    width: "180px"
  },
  root: {
    flexGrow: 1,
  },
  navigate: {
    flex: '1',
    marginLeft: '11rem',
    textAlign: 'center'
  },
  navigatePage: {
    textAlign: 'right'
  },
  buttonNavigatePage: {
    borderRadius: '6px'
  },
}));


const NavBar = (props) => {
  const {
    onClose,
    onNextDocument,
    documents = [],
    handlePrevPage,
    currentPage,
    totalPages,
    handleNextPage,
    onChangeHandler,
    inputValue,
    pageByInputRequest,
    zoom,
    setZoom,
  } = props;

  const classes = useStyles();

  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentDocument, setCurrentDocument] = useState(documents[0])

  const handleNextDoc = () => {
    const size = documents.length - 1
    if (currentIndex === size) return
    setCurrentIndex(prev => prev += 1)

  }

  const handlePrevDoc = () => {
    if (currentIndex === 0) return
    setCurrentIndex(prev => prev -= 1)
  }

  const handleChangeDoc = (e) => {
    const docId = e.target.value
    const indexDoc = documents.findIndex(el => el.id === docId)
    setCurrentIndex(indexDoc)
  }


  const handleClose = () => {
    onClose()
  }

  const handleZoom = (type) => {
    if (type === "out") {
      if (zoom >= 1.25) {
        setZoom(prev => prev - 0.25)
      }
    }

    if (type === "in") {
      if (zoom <= 2.5) {
        setZoom(prev => prev + 0.25)
      }
    }
  }

  useEffect(() => {
    if (documents.length === 0) return
    const document = documents[currentIndex]
    setCurrentDocument(document)
    onNextDocument(document)
  }, [currentIndex])


  return (
    <AppBar className={classes.appBar}>
      <Toolbar>
        <Grid container spacing={1}>


          <Grid item container xs alignItems='center' justifyContent='flex-start'>
            <Grid item>
              <IconButton edge="start" color="inherit" aria-label="close" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Button
                title='Anterior'
                variant="contained"
                color='secondary'
                onClick={handlePrevDoc}
              >
                ant
              </Button>
            </Grid>
            <Grid item>
              <FormControl
                variant='outlined'
                size='small'
                className={classes.selector}
              >
                <Select
                  title={currentDocument.name + currentDocument.extension}
                  native
                  value={currentDocument.id}
                  onChange={handleChangeDoc}
                >
                  {documents.map(doc => (
                    <option key={doc.id} value={doc.id}>{doc.name + doc.extension}</option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <Button
                title='Siguiente'
                variant="contained"
                color='secondary'
                onClick={handleNextDoc}
              >
                sig
              </Button>
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems='center'
            justifyContent='flex-start'
            xs={5}
            spacing={1}
          >
            <Grid item style={{ marginRight: "30px" }}>
              <IconButton
                disabled={zoom <= 1}
                onClick={() => handleZoom("out")}
                style={{ width: "10px", height: "10px" }}
              >
                <ZoomOutIcon style={{ color: 'white' }} />
              </IconButton>
              <IconButton
                disabled={zoom >= 2.5}
                onClick={() => handleZoom("in")}
                style={{ width: "10px", height: "10px" }}
              >
                <ZoomInIcon style={{ color: 'white' }} />
              </IconButton>
            </Grid>

            <Grid item>
              <Button onClick={handlePrevPage} variant="contained" color="secondary">
                Previa
              </Button>
            </Grid>
            <Grid item>
              <span>
                Página: <span>{currentPage}</span> / <span>{totalPages}</span>
              </span>
            </Grid>

            <Grid item>
              <Button onClick={handleNextPage} variant="contained" color="secondary">
                Siguiente
              </Button>
            </Grid>
          </Grid>

          <Grid item container alignItems='center' justifyContent='flex-end' xs={2}>
            <Grid item>
              <input
                type="number"
                className={classes.buttonNavigatePage}
                onChange={onChangeHandler}
                size={totalPages}
                id="inputValue"
                value={inputValue}
                name="inputValue"
                min="1"
                max={totalPages}
              />
              &nbsp; &nbsp;
              <Button onClick={pageByInputRequest} variant="contained" color="secondary">
                Ir a Página
              </Button>
            </Grid>
          </Grid>

        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default NavBar