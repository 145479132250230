import { useState } from "react";
import { TextFieldNative } from "../../control/calendar/forms/fields/text-field-native";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";

const NonSignerInfo = ({ submitActions, onCancel, open, selectedUsers, availableUsers, signer }) => {

  const [name, setName] = useState(signer.name ?? "");
  const [errors, setErrors] = useState({ name: "" })

  const restartErrors = () => setErrors({ name: "" })
  const addError = (type, value) => setErrors(prev => ({ ...prev, [type]: value }))

  const handleSubmit = () => {
    let existErrors = false;

    if (name) {
      if (name > 50) {
        existErrors = true
        setErrors(prev => ({ ...prev, name: 'El nombre del usuario no puede ser mayor a 100 caracteres' }))
      }
    }

    if (existErrors) return

    restartErrors()

    submitActions({ name: name.trim() })
  }

  const validateName = () => {
    const query = el => el.name?.trim().toLowerCase() === name.trim().toLowerCase();
    const existInUsers = selectedUsers.filter(el => !el.temporal).find(query);
    const existInTemporal = selectedUsers.filter(el => el.temporal).find(query);
    const existInAvailable = availableUsers.find(query);

    if (!!existInUsers) addError("name", "Este nombre ya está registrado en un usuario catalogado en la plataforma y participa como destinatario.")
    else if (!!existInAvailable) addError("name", "Este nombre ya está registrado en un usuario catalogado en la plataforma.")
    else if (!!existInTemporal) addError("name", "Este nombre ya está registrado en un usuario como destinatario.")
    else addError("name", "")
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Usuario Externo</DialogTitle>
      <DialogContent style={{ paddingTop: "0px", marginTop: "-16px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextFieldNative
              label="Nombre"
              value={name}
              message={errors.name}
              onChange={(e) => setName(e)}
              onBlur={validateName}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldNative
              label="Email"
              value={signer.email}
              type="email"
              disabled
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end" }}>
        <Button
          className="font-color mr-2"
          variant="outlined"
          onClick={() => {
            restartErrors()
            onCancel();
          }}
          disabled={false}
        >
          Cancelar
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={!!Object.values(errors).find(el => el)}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NonSignerInfo