import React, { useEffect, useState } from 'react'
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { v4 as uuidv4 } from 'uuid';
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { UserSelector } from './UserSelector';
import { UserHttp } from '../../../core/http/user.http';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { useDispatch } from 'react-redux';
import { authActions } from '../../../core/actions';
import { editExternalParticipants } from '../../../core/http/functionRequests';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  divider: {
    padding: "0px 12px 0px 12px",
  },
  content: {
    height: "450px",
    overflowY: "auto",
    overflowX: "hidden",
  },
  footer: {
    marginBottom: "0.6rem",
  },
}));

const formatText = (text) => {
  if (!text) {
    return "";
  }
  if (text.length > 40) {
    return `${text.substring(0, 37)}...`;
  }
  return text;
};

const NonSignersParticipants = (props) => {
  const {
    open,
    onClose,
    participants,
    documentActivityId,
    allowAdd = true
  } = props;

  const classes = useStyles();
  const params = useParams()
  const dispatch = useDispatch()

  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [openSelectUsers, setOpenSelectUsers] = useState(false);
  const [usersSelected, setUsersSelected] = useState(participants ?? []);

  const handleSaveParticipants = async (selected) => {
    setLoad(true)
    try {
      const externalParticipants = selected.map(el => ({ email: el.email, name: el.name }))
      await editExternalParticipants(documentActivityId, { externalParticipants })
      setUsersSelected(externalParticipants)
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        dispatch(authActions.userLoggedOut())
      }
      setError(true);
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    // const controller = new AbortController();
    // const signal = controller.signal;
    const { vaultId } = params;

    const searchUsersByVault = async () => {
      setLoad(true);

      try {

        const userhttp = new UserHttp();
        const search = await userhttp.getActiveUsersByVault(vaultId)
        const filterUsers = search.filter(el => el.email !== "soporte@kasiasolutions.com")
        setAvailableUsers(filterUsers);
      } catch (error) {
        console.error(error);
        if (error.response.status === 401) {
          dispatch(authActions.userLoggedOut())
        }
        setError(true);
      } finally {
        setLoad(false);
      }
    }

    searchUsersByVault()
    // return () => {
    //   controller.abort();
    // }
  }, []);

  return (
    <Dialog open={open} maxWidth="xs">
      <DialogTitle>
        <Grid container justifyContent='space-between'>
          <Grid item >Destinatarios</Grid>
          <Grid item>
            {
              (!load && allowAdd) && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setOpenSelectUsers(true)}
                >
                  Agregar
                </Button>
              )
            }
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent className={classes.content}>

        {error && <Alert severity="error">Hubo un problema al cargar lo usuarios inténtalo mas tarde</Alert>}

        <Grid container>
          {usersSelected.length > 0 ? (
            usersSelected.map(item => (
              <Grid key={uuidv4()} container item alignItems="center">

                <Grid item xs={1}>
                  <div
                    className="user-icon-lg"
                    title={item.name}
                  >
                    {item.name.charAt(0).toUpperCase()}
                  </div>
                </Grid>

                <Grid item xs>
                  <span title={item.name}>{formatText(item.name)}</span> <br />
                  <span className="primary-text" title={item.email}>
                    {item.email}
                  </span>
                </Grid>

                <Grid item container xs={12} className={classes.divider}>
                  <div className="col-md-12">
                    <hr />
                  </div>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item >
              No hay destinatarios seleccionados
            </Grid>
          )}
        </Grid>


      </DialogContent>

      <DialogActions>
        <Grid item container xs={12} justifyContent='flex-end' className={classes.footer} >
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onClose()}>
              Cerrar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>

      {
        openSelectUsers ? (
          <UserSelector
            open={openSelectUsers}
            users={availableUsers}
            selected={usersSelected}
            currentSigners={usersSelected}
            setUsers={setAvailableUsers}
            onSelect={handleSaveParticipants}
            onClose={() => setOpenSelectUsers(false)}
            allowExternal
          />
        ) : <></>
      }
      <Backdrop className={classes.backdrop} open={load}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  )
}

export default NonSignersParticipants